import { gql } from '@urql/core';

export const DOCUMENTS_QUERY = gql`
  query Me {
    me {
      documentContext {
        fund_key
        documentOwners {
          owner {
            ... on Person {
              id
              name
              __typename
            }
            ... on Company {
              id
              name
              coc_number
              __typename
            }
            __typename
          }
          items {
            id
            key
            name
            type
            visible_for_customer
            onfido_status
            maxSize
            template {
              absolute_url
              created_at
              extension
              filename
              id
              __typename
            }
            records {
              asset {
                absolute_url
                created_at
                extension
                filename
                id
                updated_at
                filesize {
                  human
                  raw
                  __typename
                }
                __typename
              }
              created_at
              id
              name
              updated_at
              downloadable_for_customer
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
